/* .boocard {
    position: relative;
    display: flex;
    display: -ms-flexbox;
    flex-direction: row;
    width: 60%;
    margin: 3rem auto;
    filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15));
}

.boocard_inner {
    display: flex;
    display: -ms-flexbox;
}

.boocard_dets {
    flex: 2;
    background-color: #fff;
    min-height: 150px;
    padding: 1rem;
}

.boocard_left {
    border-radius: 5px 0 0 5px;
}

/* .boocard_dets .boocard_ttl {
    margin-top: 0;
} */

/* .boocard_dets ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.boocard_dets ul li {
    margin: 0.7rem 0;
}

.boocard_divider {
    position: relative;
    background-color: #fff;
    background-image: linear-gradient(90deg, #fff 49%, #15843C 50%);
    margin: 10px 0;
    width: 20px;
}

.boocard_divider::before, .boocard_divider::after {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    border: 5px solid transparent;
    border-top-color: #fff;
    left: 50%;
    transform: translateX(-50%) rotate(135deg);
    border-radius: 50%;
}

.boocard_divider::before {
    top: -25px;
    border-top-color: #15843C;
    border-right-color: #fff;
}

.boocard_divider::after {
    border-right-color: #15843C;
    border-top-color: #fff;
    bottom: -25px;
    transform: translateX(-50%) rotate(-45deg);
}

.boocard_date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 2;
    background-color: #15843C;
    min-height: 150px;
    padding: 0.5rem;
    border-radius: 0 5px 5px 0;
    color: #fff;
}

.boocard_date .date {
    font-size: 1rem;
    margin-bottom: 0.2rem;
    color: #fff;
}

.boocard_date .dash {
    font-size: 2rem;
    line-height: 20px;
} */


.mybookings_sec h2.no_booking {
    font-size: 20px;
    text-align: center;
    background-color: #fff;
}

.boocard_dets ul li b {
    display: block;
    color: #000;
}

.boocard_dets ul li {
    color: #616161;
}

.boocard_dets ul li.name {
    text-transform: uppercase;
}

















.mybookings_sec {
  background-color: #dadde6;
  padding: 3rem 0;
}



.boocard {
    width: 60%;
    background-color: #fff;
    color: #989898;
    margin: 2rem auto;
    text-transform: uppercase;
    border-radius: 4px;
    position: relative;
    display: flex;
    display: -ms-flexbox;
}

.boocard_date {
  width: 30%;
  position: relative;
  text-align: center;
  border-right: 2px dashed #dadde6;
}

.boocard_date:before, .boocard_date:after {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  background-color: #DADDE6;
  position: absolute;
  top: -15px ;
  right: -15px;
  z-index: 1;
  border-radius: 50%
}

.boocard_date:after {
  top: auto;
  bottom: -15px;
}

/* .boocard_date .time span {
    display: block;
}

.boocard_date .time span:first-child {
  color: #2b2b2b;
  font-weight: 600;
  font-size: 250%;
}

.boocard_date .time span:last-child {
  text-transform: uppercase;
  font-weight: 600;
  margin-top: -10px;
} */

.boocard ul.left {
    width: 70%;
    text-align: left;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.boocard ul.left li {
    margin: 10px 0;
}

.boocardCont {
    width: 70%;
    font-size: 85%;
    padding: 10px 10px 30px 40px;
}

.boocardCont h3 {
  color: #3C3C3C;
  font-size: 130%;
}

.boocard ul {
    margin: 0;
    padding: 0;
}

.boocard ul li {
    margin: 15px 0;
}

.boocard ul li i {
    margin: 0 5px;
}

.boocardCont a {
  text-decoration: none;
  color: #D8DDE0;
}






/** Responsive CSS starts here **/

@media (max-width: 1199.98px) {
    .boocard {
        width: 70%;
    }
}

@media (max-width: 991.98px) {

    .boocard {
        width: 80%;
    }

    .boocardCont {
        width: 65%;
        padding: 10px 10px 30px 30px;
    }

    .boocard_date {
        width: 35%;
    }
}

@media (max-width: 767.98px) {
    .boocard {
        width: 100%;
    }
}

@media (max-width: 575.98px) {
    .mybookings_sec {
        background-color: #dadde6;
        padding: 1rem 0;
    }

    .boocard {
        flex-direction: column-reverse;
    }

    .boocardCont, .boocard_date {
        width: 100%;
        min-height: 80px;
        padding: 15px;
    }

    .boocard_date {
        border-right: none;
        border-top: 1.5px dashed #dadde6;
    }

    .boocard_date:before, .boocard_date:after {
        width: 25px;
        height: 25px;
    }
    
    .boocard_date:after {
        left: -15px;
        top: -15px;
    }

    .boocard ul.left {
        position: static;
        transform: none;
    }

    .boocard ul.left li {
        margin: 15px 0;
    }
}

@media (max-width: 475.98px) {
    .boocard_dets {
        padding: 0;
        min-height: unset;
    }

    .boocard_inner {
        flex-direction: column;
        padding: 1rem;
        background-color: #fff;
    }
}