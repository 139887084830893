.contact_sec .contact_inner {
    padding: 4rem 1rem;
    position: relative;
}

/* .contact_sec .contact_inner #tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    z-index: -1;
} */

.contact_sec .contForm {
    width: 60%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    padding: 2rem;
    background-color: #f4f4f4;
    border-radius: 8px;
}










/** Responsive CSS starts here **/

@media (max-width: 991.98px) {
    .contact_sec .contForm {
        width: 80%;
    }
}

@media (max-width: 575.98px) {
    .contact_sec .contact_inner {
        padding: 2rem 1rem;
    }
}

@media (max-width: 475.98px) {
    .contact_sec .contForm {
        width: 95%;
    }
}