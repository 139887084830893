.iframe_sec {
    padding: 2rem 0;
}

.iframe_inner {
    position: relative;
    width: 100vw;
    height: 600px;
    overflow: hidden;
}

.iframe_inner iframe {
    position: absolute;
    width: 100%;
    height: calc(100% + 30%);
    top: -40%;
    left: 0;
    border: none;
}








/** Responsive CSS starts here **/

@media (max-width: 1400px) {
    .iframe_inner {
        height: 500px;
    }
    
    .iframe_inner iframe {
        top: -30%;
    }
}

@media (max-width: 1199.98px) {
    .iframe_inner {
        height: 450px;
    }

    .iframe_inner iframe {
        top: -25%;
    }
}

@media (max-width: 991.98px) {

    .iframe_sec {
        padding: 1rem 0;
    }
    
    .iframe_inner {
        height: 350px;
        top: -20%;
    }
}
@media (max-width: 767.98px) {
    .iframe_inner {
        height: 300px;
    }
}

@media (max-width: 650px) {
    .iframe_inner {
        height: 250px;
    }
}

@media (max-width: 575.98px) {
    .iframe_inner {
        height: 220px;
    }
}

@media (max-width: 475.98px) {

    .iframe_inner {
        height: 180px;
    }
}

@media (max-width: 375.98px) {
    .iframe_sec {
        padding: 0;
    }
    .iframe_inner {
        height: 150px;
    }
}