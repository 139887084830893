/** Button Hover CSS **/

.butn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    background-color: transparent;
    padding: 1rem;
    height: 3rem;
    width: 9rem;
    border-radius: 2px !important;
    border: none;
    color: transparent;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease-in-out !important;
}

.butn::before {
    background: #fff;
    content: "";
    height: 80px;
    opacity: 0;
    position: absolute;
    top: -20px;
    transform: rotate(35deg);
    width: 20px;
    transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1);
}

.butn_sm {
    width: 6rem;
    font-size: 12px !important;
    padding: 0.5rem !important;
    height: 2.5rem;
}

.butn_block {
    width: 100% !important;
}

.butn_success {
    color: #fff !important;
    background-color: #15843C !important;
    background-image: linear-gradient(180deg, #15843C 0%, #36BD65 100%) !important;
}

.butn_success::before {
  left: -50%;
}

.butn:hover {
  transform: translateY(-3px);
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
}

.butn_success:hover::before {
    left: 120%;
    opacity: 0.5;
}

.butn span {
  z-index: 20;
}

.fullButn {
    width: 100% !important;
}

/** Button Hover CSS ends here **/

/** Link Effect CSS **/

.linkEffect a {
    position: relative;
    overflow: hidden;
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

.linkEffect a span {
    display: block;
    transition: transform 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.linkEffect a:after {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    content: attr(data-hover);
    display: inline;
    text-align: center;
    transition: top 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.linkEffect a:hover {
    color: #27a252;
}

.linkEffect a:hover span {
    color: #27a252;
    transform: translateY(-100%);
}

.linkEffect a:hover:after {
    top: 0;
}

/** Link Effect CSS ends here **/








/** Responsive CSS starts here **/

@media (max-width: 991.98px) {
    .butn {
        font-size: 13px;
        padding: 0.5rem;
        height: 2.5rem;
        width: 8rem;
    }

    .linkEffect a {
        font-size: 12px;
    }
}

@media (max-width: 767.98px) {
    .linkEffect a {
        padding: 0 0 10px;
    }
}

@media (max-width: 575.98px) {
    .butn {
        font-size: 12px;
        padding: 0.3rem;
        height: 2rem;
        width: 7rem;
    }

    .linkEffect a {
        font-size: 13px;
    }
}