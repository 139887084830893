/** Font Rule **/
@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Russo One';
  src: url('../fonts/RussoOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mulish';
  src: url('../fonts/Mulish-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/** Font Rule Ends here **/





/* GRID CSS */
.custom-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  @media (min-width: 576px) {
    .custom-container {
      max-width: 600px;
    }
  }
  @media (min-width: 768px) {
    .custom-container {
      max-width: 720px;
    }
  
  }
  @media (min-width: 992px) {
    .custom-container {
      max-width: 960px;
    }
  
  }
  @media (min-width: 1200px) {
    .custom-container {
      max-width: 1080px;
    }
  }

  @media (min-width: 1400px) {
    .custom-container {
      max-width: 1200px;
    }
  }
  @media (min-width: 1600px) {
    .custom-container {
      max-width: 1350px;
    }
  }

  /* GRID CSS Ends here */

  .custom_row {
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  .leftCol, .rightCol {
    padding: 0 15px;
  }


  /** Form CSS **/

  form .formGrp {
      margin: 0 0 15px;
  }

  form label {
      display: block;
      font-size: 14px;
      font-weight: 600;
      font-family: 'Mulish';
      margin-bottom: 10px;
  }

  form input:not([type="submit"]), form select {
      display: inline-block;
      width: 100%;
      height: 45px;
      line-height: 45px;
      margin: 0 0 10px;
      padding: 10px 12px;
      font-size: 13px;
      font-family: 'Mulish';
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 4px;
      outline: none;
      transition: all 0.3s ease-in;
  }

  form textarea {
      width: 100%;
      height: 120px;
      resize: none;
      margin: 0 0 10px;
      padding: 10px 12px;
      font-size: 14px;
      font-family: 'Mulish';
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 4px;
      outline: none;
      transition: all 0.3s ease-in;
  }

  form input:not([type="submit"]):focus, form textarea:focus, form select:focus {
      border-color: #ccc;
  }

  .inlineForm .formGrp {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .inlineForm .formGrp input {
    margin: 0;
    border-radius: 0;
    height: 45px;
    line-height: normal;
  }



/** Form CSS ends here **/







/** Responsive CSS starts here **/

@media (max-width: 575.98px) {

  form input:not([type="submit"]), form select {
      height: 40px;
      line-height: 40px;
      margin: 0 0 8px;
      padding: 8px 10px;
      font-size: 12px;
      border-radius: 2px;
  }

  .inlineForm .formGrp input {
      height: 35px;
      font-size: 12px;
  }

  form textarea {
      height: 100px;
      padding: 8px 10px;
  }
}