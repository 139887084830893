.faqSec {
    padding: 3rem 0;
}







/** Responsive CSS starts here **/

@media (max-width: 991.98px) {
    .faqSec {
        padding: 2rem 0;
    }
}