.book_form_sec {
    padding: 3rem 0;
    background-color: #F4F4F4;
}

.book_form_sec form.rs-form {
    padding: 1rem;
    width: 60%;
    margin: 0 auto;
}

.book_form_sec .rs-form .rs-form-control-wrapper,
.book_form_sec .rs-form .rs-form-control-wrapper input {
    display: block;
    width: 100%;
}

.book_form_sec .rs-form .rs-form-control-label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Mulish';
    margin-bottom: 10px;
}

.book_form_sec .rs-form .rs-picker-toggle-wrapper {
    display: block;
}

.book_form_sec .rs-form input {
    border-radius: 4px;
    padding: 8px 10px;
    border: 1px solid #e5e5ea;
    outline: none;
    width: 100%;
    transition: border .15s ease-in-out;
}

.book_form_sec .rs-form  input:hover {
    border-color: #3498ff;
}

.book_form_sec .rs-form div:not(.rs-picker-search-input) input:focus {
    box-shadow: 0 0 0 3px rgba(52,152,255,.25);
}

.book_form_sec .rs-form div.rs-picker-search-input > input:focus {
    box-shadow: none;
}

.dropzone {
    background: #fff;
    border: 1.5px dashed #999;
    overflow: auto;
    padding: 10px;
    width: 100%;
    height: 180px;
    border-radius: 4px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    position: relative;
    transition: all .15s linear;
}

.dropzone label {
    bottom: 0;
    font-size: 14px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    text-align: center;
}

.dropzone input[type="file"] {
    display: none;
}

.dropzone + button.upload {
    display: block;
    margin: 10px;
    width: calc(100% - 20px);
    max-width: 90px;
    margin-left: auto;
    background-color: #1675e0;
    padding: 8px;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
}

.dropzone + button.upload:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.dropzone + button.upload:not(:disabled):hover {
    opacity: 0.7;
}

.dropzone button.delete {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 10px;
    background-color: crimson;
    color: #fff;
    border-radius: 0 0 0 15px;
    z-index: 2;
}

.dropzone button.delete:hover {
    opacity: 0.7;
}








/** Responsive CSS starts here **/

@media (max-width: 767.98px) {
    .book_form_sec form.rs-form {
        width: 90%;
    }
}

@media (max-width: 575.98px) {

    .book_form_sec {
        padding: 2rem 0;
    }

    .book_form_sec .rs-form  input {
        padding: 8px;
        font-size: 12px;
    }

    .book_form_sec form.rs-form {
        width: 100%;
    }

    .book_form_sec .rs-form .rs-form-control-label {
        font-size: 12px;
    }

    .book_form_sec .rs-picker-input .rs-picker-toggle,
    .book_form_sec .rs-picker-daterange .rs-picker-toggle,
    .book_form_sec .rs-radio-checker label {
        font-size: 12px !important;
    }
}