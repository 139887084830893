@import url(assets/css/basic.css);
@import url(assets/css/widgets.css);
@import url(assets/css/common.css);
@import url(assets/css/home.css);
@import url(assets/css/faq.css);
@import url(assets/css/clients.css);
@import url(assets/css/contact.css);
@import url(assets/css/login.css);
@import url(assets/css/booking.css);
@import url(assets/css/mybookings.css);
@import url(ui/css/style.css);
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css');

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  overflow-x: hidden;
  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #000 !important;
  transition: all 0.2s ease-in;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a,
a:active,
a:visited,
a:focus {
  text-decoration: none !important;
  color: #000;
}

p {
  margin: 0;
}

/** Header CSS **/

.innerHeader {
  background: linear-gradient(180deg, #15843c 0%, #36bd65 100%);
  padding: 10px 0;
}

.innerHeaderWrap {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: flex-end;
}

.innerHeaderWrap ul {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 20px;
  color: #fff;
  font-size: 14px;
  margin: 0;
}

.innerHeaderWrap ul img {
  vertical-align: middle;
}

.innerHeaderWrap ul a {
  text-decoration: none;
  color: #fff;
  transition: all 0.3s ease;
}

.innerHeaderWrap ul a:hover {
  letter-spacing: 1px;
}

.innerHeaderWrap ul.right_info a {
  width: 25px;
  height: 25px;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
}

.innerHeaderWrap ul.right_info a:hover {
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.7);
  border-color: transparent;
}

.mainHeader .header_wrap {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}

.mainHeader .logo img {
  max-width: 235px;
}

.navigationWrapper ul {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 10px;
}

.navigationWrapper ul li:last-child {
  margin-right: 0;
}
.navigationWrapper ul li.menu_item a,
.navigationWrapper .dropdown.active .dropdown_menu a {
  color: #000;
}

.navigationWrapper ul li.menu_item a:hover,
.navigationWrapper .dropdown.active .dropdown_menu a:hover {
  color: #27a252;
}

.navigationWrapper ul li.menu_item a:hover span,
.navigationWrapper .dropdown.active .dropdown_menu a:hover span {
  color: #27a252;
}
.navigationWrapper ul li.menu_item img {
  width: 25px;
  height: 25px;
  margin-right: 2px;
}

.hamburger {
  display: none;
}

.navigationWrapper ul li.menu_item .dropdown {
  margin: 0;
  position: relative;
}

.navigationWrapper ul li.menu_item span.dropdown_text {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 10px;
  color: #fff;
  background-color: #15843c;
  text-align: center;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 0 0;
  cursor: pointer;
}

.navigationWrapper .dropdown_menu {
  top: 55px;
  right: 0;
  position: absolute;
  width: 150px;
  height: 0;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  z-index: 999;
  transition: all 0.3s ease;
}

.navigationWrapper .dropdown.active .dropdown_menu {
  visibility: visible;
  opacity: 1;
  height: 110px;
  box-shadow: 0px 8px 8px 5px rgba(0, 0, 0, 0.2);
}

.navigationWrapper .dropdown_menu ul {
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
}

.navigationWrapper .dropdown_menu ul li a {
  text-transform: uppercase;
  margin: 0 0 5px;
  cursor: pointer;
}

.navigationWrapper ul li.menu_item:not(.dropdown) .butn {
  width: 7rem;
  height: 2.5rem;
}

/** Header CSS Ends here **/

/** Common CSS **/

header.mainHeader {
  background-color: #fff;
  position: relative;
  z-index: 1;
}

footer {
  position: relative;
  z-index: 1;
}

/** Footer CSS starts here **/

.footer-sec {
  padding: 3rem 0;
  background: linear-gradient(180deg, #15843c 0%, #36bd65 100%);
}

.footerCol-6,
.footerCol-3 {
  padding: 0 15px;
}

.footerCol-6 {
  flex: 1 1 35%;
  max-width: 35%;
  margin-right: auto;
}

.footerCol-3 {
  flex: 1 1 30%;
  max-width: 30%;
}

.footerCol_inner h4 {
  color: #fff;
  font-family: 'Russo One';
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 20px;
  text-align: left;
}

.footerCol_inner p {
  font-size: 15px;
  line-height: 25px;
  color: #fff;
  font-weight: 400;
}

ul.foot_menu {
  display: inline-block;
}

ul.foot_menu li a {
  text-align: left;
  text-transform: capitalize;
  color: #fff;
  margin: 0 0 15px;
  padding: 0;
  display: inline-block;
}

ul.foot_menu li a:hover {
  color: #000;
}

.footerCol_inner ul.address li {
  margin: 0 0 15px;
  color: #fff;
  font-weight: 500;
}

.footerCol_inner ul.address li a {
  color: #fff;
}

.footerCol_inner ul.address li i {
  margin-right: 10px;
}

.footerCol_inner ul.address li span img {
  margin-right: 10px;
  vertical-align: middle;
}

.footerCol_inner ul.social_info {
  margin: 20px 0;
}

ul.social_info {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  gap: 20px;
  color: #fff;
}

ul.social_info li a {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  display: flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in;
}

ul.social_info li a img {
  vertical-align: middle;
}

ul.social_info li a:hover {
  box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.7);
  border-color: transparent;
}

footer .copyright {
  text-align: center;
  background: linear-gradient(180deg, #15843c 0%, #36bd65 100%);
  color: #fff;
  padding: 12px 0;
  font-size: 12px;
  font-weight: 500;
}

footer .copyright a {
  color: #fff;
}

footer .copyright img {
  width: 15px;
  vertical-align: middle;
}

/** Footer CSS ends here **/

/* Privacy Policy Css */
.PrivacyPolicy {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  line-height: 1.6;
  padding-top: 3% !important;
}
.PrivacyPolicy ul {
  list-style: disc;
}

/** Responsive CSS starts here **/

@media (min-width: 768px) {
  .navigationWrapper ul li.menu_item span.dropdown_text:hover {
    background-color: #2cb75c;
  }

  .navigationWrapper ul li {
    margin: 0 8px;
  }
}

@media (min-width: 992px) {
  .navigationWrapper ul li.menu_item img {
    display: none;
  }

  .navigationWrapper ul li.menu_item .dropdown_text img {
    display: block;
  }

  .navigationWrapper ul li {
    margin: 0 10px;
  }
  .PrivacyPolicy {
    max-width: 70%;
    margin: 0 auto;
  }
}

@media (max-width: 1199.98px) {
  .mainHeader .header_wrap {
    padding: 15px 0;
  }

  .mainHeader .logo img {
    max-width: 220px;
  }
}

@media (max-width: 991.98px) {
  .navigationWrapper ul li.menu_item:not(.dropdown) .butn {
    width: 3rem;
    height: 2rem;
    font-size: 0;
  }

  .innerHeaderWrap ul {
    gap: 15px;
  }

  .mainHeader .logo img {
    max-width: 150px;
  }

  .navigationWrapper ul li.menu_item span.dropdown_text {
    font-size: 0;
    padding: 6px 8px;
    border: 1px solid #15843c;
  }

  .navigationWrapper ul li.menu_item img {
    width: 20px;
    height: 20px;
  }

  .navigationWrapper .dropdown_menu {
    top: 40px;
    width: 100%;
  }

  .navigationWrapper .dropdown.active .dropdown_menu {
    height: 130px;
  }

  .navigationWrapper .dropdown_menu ul li a {
    margin: 0;
  }

  .footerCol-3 {
    flex: 1 1 32%;
    max-width: 32%;
  }

  .footerCol_inner ul.address li {
    font-size: 14px;
  }

  .footerCol_inner ul.address li i,
  .footerCol_inner ul.address li span img {
    margin-right: 5px;
  }

  .footerCol_inner p {
    font-size: 14px;
    line-height: normal;
  }
}

@media (max-width: 767.98px) {
  .mainHeader .logo img {
    max-width: 180px;
  }

  .mainHeader .header_wrap {
    position: relative;
  }

  .navigationWrapper {
    margin: 0;
    position: absolute;
    right: 0;
    top: 70px;
    width: 300px;
    height: 0;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
    z-index: 999;
    overflow: hidden;
    transition: all 0.3s ease;
  }

  .mainHeader.showMenu .navigationWrapper {
    padding: 20px;
    height: 250px;
    overflow-y: auto;
    border-bottom: 4px solid #15843c;
    border-radius: 0 0 15px 15px;
  }

  .navigationWrapper ul li.menu_item span.dropdown_text {
    font-size: 12px;
  }

  .navigationWrapper ul {
    flex-direction: column;
  }

  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger span.bar {
    width: 25px;
    height: 3px;
    position: relative;
    display: block;
    background: linear-gradient(180deg, #15843c 0%, #36bd65 100%);
    transition: all 0.2s ease;
  }

  .hamburger span.bar:before,
  .hamburger span.bar:after {
    content: '';
    width: 25px;
    height: 3px;
    position: absolute;
    background: linear-gradient(180deg, #15843c 0%, #36bd65 100%);
    transition: all 0.2s ease;
  }

  .hamburger span.bar:before {
    top: -8px;
  }

  .hamburger span.bar:after {
    top: 8px;
  }

  .showMenu .hamburger span.bar {
    height: 0;
  }

  .showMenu .hamburger span.bar:before {
    transform: rotate(135deg);
    top: -5px;
  }

  .showMenu .hamburger span.bar:after {
    transform: rotate(-135deg);
    top: -5px;
  }

  .navigationWrapper ul li {
    width: 100%;
    margin: 0 10px;
  }

  .navigationWrapper ul li.menu_item:not(.dropdown) .butn {
    margin: 0 auto;
    display: block;
    text-align: center;
    line-height: 1.5rem;
    font-size: 13px;
    width: 100%;
    height: 2.5rem;
  }

  .navigationWrapper ul li.menu_item:not(.dropdown) .butn img {
    display: none;
  }

  .innerHeaderWrap ul {
    font-size: 13px;
  }

  .footer-sec {
    padding: 2.5rem 0 2rem;
  }

  .footerCol_inner ul.address li {
    margin: 0 0 10px;
    font-size: 14px;
  }

  .footerCol_inner h4 {
    font-size: 16px;
    margin: 0 0 15px;
  }

  .footerCol-6 {
    flex: 1 1 90%;
    max-width: 90%;
  }

  .footerCol-3 {
    flex: 1 1 50%;
    max-width: 50%;
  }
}
.error-input {
  border: 1px solid red !important;
}

@media (max-width: 600px) {
  /** RS Form CSS **/

  .rs-picker-daterange-panel .rs-picker-daterange-calendar-group .rs-calendar .rs-calendar-table-cell-content {
    font-size: 12px;
  }

  .rs-picker-daterange-panel .rs-picker-daterange-calendar-group .rs-calendar {
    height: auto;
    min-width: auto;
  }
}

@media (max-width: 575.98px) {
  .rs-picker-daterange-panel .rs-picker-daterange-calendar-group {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    height: auto;
    min-width: auto;
  }

  .rs-picker-daterange-panel .rs-picker-toolbar {
    flex-direction: column;
  }

  .rs-picker-daterange-panel .rs-picker-daterange-calendar-group .rs-calendar .rs-calendar-table-cell-content {
    border-radius: 4px;
    font-size: 10px;
    padding: 3px;
    line-height: 25px;
  }

  .rs-picker-daterange-panel .rs-picker-daterange-calendar-group .rs-calendar {
    height: auto;
  }

  .rs-picker-daterange-panel .rs-picker-daterange-calendar-group .rs-calendar:first-child {
    border: none;
    border-bottom: 1px solid var(--rs-border-primary);
  }

  .rs-picker-daterange-panel .rs-picker-toolbar .rs-btn-sm {
    font-size: 12px;
    line-height: 15px;
    padding: 5px;
  }

  .mainHeader .logo img {
    max-width: 170px;
  }

  .navigationWrapper {
    width: 290px;
  }

  .innerHeaderWrap {
    gap: 10px;
  }

  .innerHeaderWrap ul {
    font-size: 12px;
    gap: 5px;
  }

  .innerHeaderWrap ul.left_info {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-sec {
    background: linear-gradient(92deg, #15843c 0%, #36bd65 100%);
  }

  .footer-sec .custom_row {
    gap: 20px 0;
  }

  .mainHeader .header_wrap {
    padding: 10px 0;
  }

  .mainHeader.showMenu .navigationWrapper {
    height: 200px;
    top: 55px;
  }

  .footerCol-6 {
    flex: 1 1 95%;
    max-width: 95%;
  }

  .footerCol_inner p {
    font-size: 13px;
  }
}

@media (max-width: 475.98px) {
  .footer-sec {
    padding: 2rem 0;
  }

  .footerCol-3 {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .footerCol_inner ul.social_info {
    margin: 15px 0;
  }

  footer .copyright {
    padding: 8px 0;
    font-size: 10px;
  }
}
